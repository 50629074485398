import { EventEmitter, Injectable } from '@angular/core';
import {HttpBaseService} from './http-base.service';
import {StorageService} from './storage.service';
import { Router } from '@angular/router';
import { removeSessionUser, setSessionUser } from '../../store/user/user.action';
import { removeReviewer, setReviewer } from '../../store/reviewer/reviewer.action';
import { Store } from '@ngrx/store';
import { FirebaseMessagingService } from './firebase-messaging.service';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { ConfigurationService } from './configuration.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  eventEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpBaseService,
    private storageService: StorageService,
    private firebaseMessagingService: FirebaseMessagingService,
    private store: Store,
    private router: Router,
    private userService: UserService,
    private configurationService: ConfigurationService
  ) { }

  public login(payload:any) {
    return this.http.post('auth/login', payload);
  }

  public signup(payload: any){
    return lastValueFrom(this.http.post('auth/signup', payload));
  }

  public refreshToken() {
    const payload = {
      refreshToken: this.storageService.getCookieValueByKey('refreshToken'),
      expiryFor: 'web',
    }
    return this.http.post('auth/refreshToken', payload);
  }

  public getSessionUser() {
    return this.http.get('my');
  }

  public removeAllExcept(keysToKeep: string[]): void{
    const itemsToKeep: { [key: string]: string } = {};
    
    keysToKeep.forEach(key => {
      const value = localStorage.getItem(key);
      if (value !== null) {
        itemsToKeep[key] = value;
      }
    });
  
    localStorage.clear();
  
    Object.keys(itemsToKeep).forEach(key => {
      localStorage.setItem(key, itemsToKeep[key]);
    });
  };
  

  public logout():void{
    this.firebaseMessagingService.logoutFromFirebase();
    this.storageService.removeAllCookies()
    this.store.dispatch(removeReviewer());
    this.store.dispatch(removeSessionUser());
    this.removeAllExcept(['isSideNavOpen']);
    window.location.href = `${environment.clientHost}login`;
  }

  public logoutWithoutNavigation():void{
    this.firebaseMessagingService.logoutFromFirebase();
    this.storageService.removeAllCookies()
    this.store.dispatch(removeReviewer());
    this.store.dispatch(removeSessionUser());
    this.removeAllExcept(['isSideNavOpen']);
  }

  async autoLogin(token: string, state: string, isMobile: boolean, firmId: string) {    
    const UserObject = JSON.parse(decodeURIComponent(token));
    const externalAuth = UserObject?.externalAuth; 
    this.removeAllExcept(['isSideNavOpen']);
    this.storageService.setUserToken(UserObject);
    this.storageService.setCookieValue('firebaseToken',UserObject.firebaseToken);
    await this.configurationService.getConfiguration();
    let sessionUser;
    try{
      if(UserObject.firmUser){
        sessionUser = await this.userService.getUserById(UserObject.firmUser);
      }else{
        sessionUser = await lastValueFrom(this.http.get(`my`));
      }
      if (!sessionUser.message) {
        this.store.dispatch(setReviewer(sessionUser));
        this.store.dispatch(setSessionUser(sessionUser));
        const singleTenant = sessionUser.tenants.length ? sessionUser.tenants[0] : '';
        const currentTenant  = sessionUser.tenants.find(
          (tenant:any) => tenant.firm._id === firmId
        );
        this.storageService.setTenant(currentTenant || singleTenant);
        
          if (isMobile) {
            // handle mobile-specific logic if needed
          }
          if (state === 'my-profile' && isMobile) {
            this.router.navigateByUrl('/reviewer/profile/mobile');
          }
          if ( externalAuth === 'true') {

            if(sessionUser.role === 'REVIEWER'){
              const tenant = sessionUser.tenants.find((t:any)=> t.reviewerStatus === 'INCOMPLETE');
              if(tenant){
                const firmId = tenant.firm._id ? tenant.firm._id.toString() : tenant.firm;
                this.router.navigateByUrl(`/onboarding?firm=${firmId}`); 
              }else{
                this.router.navigateByUrl('/reviewer'); 
              }
            }
            else{
              this.router.navigateByUrl('/client'); 
            }
         }
      }
    }catch(error){
      this.logout();
    }
  }

  public changePassword (oldPass: string, newPass: string, confirmPass: string) {
    let refreshObject = {
      refreshToken: this.storageService.getCookieValueByKey('refreshToken'),
      expiryFor: 'web',
    };
    let pass: any = {} ;
    pass.oldPassword = oldPass;
    pass.newPassword = newPass;
    pass.confirmPassword = confirmPass;
    pass.refreshObject = refreshObject;
    return this.http.put('auth/changePassword', pass);
  }

  forgotPassword(email: {email: string}) {
    return this.http.put('auth/forgotPassword', email);
  }

  resetPassword(password: any){
      return this.http.put('auth/resetPassword', password);
  }

  getUserByHash (hash:string) {
    return this.http.get(`auth/getUserByRecoveryHash/${hash}`);
  };

  getNotInterestedUserByHash (hash:string) {
    return lastValueFrom(this.http.get(`auth/getNotInterestedUserByRecoveryHash/${hash}`));
  };

  updateUser(user: any) {
    return this.http.put('auth/user/update', user);
  };

  sendAdminVerificationPhoneSms(adminId: string, mobileNo: string) {
    const body = {
      _id: adminId,
      ...(mobileNo ? { mobile: mobileNo } : {})
    }
    return this.http.put('auth/sendVerificationPhoneSms', body);
  }
  
  verifyPhone(body: any ) {
    return this.http.put('auth/verifyPhone', body);
  }

  isAuthenticated (){
    return !!(this.storageService.getToken());
  }
  preLoginVerification(email:string){
    return this.http.post('auth/pre-login-verification',{email});
  }

}
